import img1 from '../../../img/c-1.png';
import img2 from '../../../img/c-2.png';
import img3 from '../../../img/c-3.png';
import img4 from '../../../img/c-4.png';
import img5 from '../../../img/c-5.png';
import img6 from '../../../img/c-6.png';
import img7 from '../../../img/c-7.png';
import img8 from '../../../img/c-8.png';
import img9 from '../../../img/c-9.png';
import img10 from '../../../img/c-10.png';
import img11 from '../../../img/c-11.png';
import img12 from '../../../img/c-12.png';
import img13 from '../../../img/c-13.png';
import img14 from '../../../img/c-14.png';
import img15 from '../../../img/c-15.png';
import img16 from '../../../img/c-16.png';
import img17 from '../../../img/c-17.png';
import img18 from '../../../img/c-18.png';
import img19 from '../../../img/c-19.png';
import img20 from '../../../img/c-20.png';
import img21 from '../../../img/c-21.png';
import img22 from '../../../img/c-22.png';
import img23 from '../../../img/c-23.png';
import img24 from '../../../img/c-24.png';

import Image from '../../../img/Image.png';

function OurClient() {
    const images = [
        img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
        img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
        img21, img22, img23, img24
    ];
 

    return ( 
        <div className="flex flex-col items-center pb-16 bg-cover" style={{ backgroundImage: 'url(' + Image + ')' }}>
            <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-red-600 from-10%  to-black italic tracking-wider pb-6">Our Valuable Clients</h1>
            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 my-4'>
                {images.map((Img, index) => (
                    <div key={index} className='relative group'>
                         <img src={Img} alt={`Component ${index + 1}`} className='w-60 shadow-lg  h-28 border-4 bg-white   rounded-lg ' />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OurClient;
