import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Contact/Footer'
import AboutComponent from './AboutComponent'

function About() {
  return (
        <div>
         <div className=""><Navbar/></div>
      <div className='mt-72 sm:mt-52'><AboutComponent/></div>
      <div><Footer/></div>
    </div>
  )
}

export default About
