import React from 'react';
import home from '../../img/GOLD_TESTING_MACHINE-.png';

function Homepage2() {
  return (
    <div className="sm:h-screen pt-10  bg-center bg-gradient-to-r to-gray-500 from-black ">
      <section className="dark:bg-gray-100 dark:text-gray-800">
        <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 xl:py-32 lg:flex-row lg:justify-between">
          <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 lg:w-1/2">
            <img src={home} alt="Gold Testing Machine" className="object-contain max-w-full h-auto lg:h-96 xl:h-112 2xl:h-228" />
          </div>
          <div className="flex flex-col mt-28 justify-center p-6 text-center text-white rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
            <h1 className="text-5xl font-bold leading-none sm:text-6xl">Gold Testing Machine
            </h1>
            <p className="mt-6 mb-8 text-lg sm:mb-12">Optimize Your Quality Control Process with Our Testing Machines
              <br className="hidden md:inline lg:hidden" />Where precision and reliability meet. Explore our range of advanced testing machines designed to streamline your quality control process.
            </p>
			<div className="">
              <a rel="noopener noreferrer" href="#" className="inline-block px-6 py-3 text-lg font-semibold leading-none text-white bg-blue-500 rounded hover:bg-blue-600">Know More</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Homepage2;
