import React, { useState } from 'react';
import UpperNav from './UpperNav';
import logo from '../../img/Victor logo.png';
import { Link } from 'react-router-dom';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [productDropdownOpen, setProductDropdownOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleProductDropdown = () => {
        setProductDropdownOpen(!productDropdownOpen);
    };

    return (
        <header className="fixed w-[100vw] top-0 left-0 right-0 z-50  bg-white">
            <UpperNav />
            <nav className="flex items-center justify-between w-[100vw]  px-4 py-4 md:py-0 text-lg text-gray-600">
                <div className="flex items-center justify-between  w-full">
                    <div className='flex items-center gap-6'>
                        <img src={logo} alt="Victor Logo" className="w-16 h-16 sm:w-24 sm:h-24 pt-2" />
                        <h1 class=" sm:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-red-600 from-40%  to-black">Victor Enterprises</h1>  
                    </div>
                    <div className="md:hidden ">
                        <button onClick={toggleMobileMenu} className="text-red-800 p-4 hover:text-red-800 focus:outline-none">
                            <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                {menuOpen ? (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                ) : (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={`md:flex ${menuOpen ? 'block' : 'hidden'} md:items-center md:w-full `}>
                    <ul className="pt-4 pr-8  text-base text-gray-700 md:flex md:justify-between md:pt-0 md:w-full md:items-center">
                        <li>
                            <Link to='/' className="md:px-10 py-2 block text-gray-600 hover:text-red-500 text-lg font-bold">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to='/about' className="md:px-10 py-2 block text-gray-600 hover:text-red-500 text-lg font-bold">
                                About Us
                            </Link>
                        </li>
                        <li>
                            <div className="relative">
                                <span
                                    onClick={toggleProductDropdown}
                                    className="md:px-10 py-2 block text-gray-600 hover:text-red-500 text-lg font-bold cursor-pointer"
                                >                                    Products
                                </span>
                                {productDropdownOpen && (
                                    <div className="relative top-full left-0 mt-2 w-full origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg md:absolute md:left-auto md:right-0 md:top-auto md:mt-0 md:w-56">
                                        <div className="py-1">
                                            <Link to='/cashcount' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                Cash Counting Machine
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>
                                            <Link to='/goldmachine' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                Gold Testing Machine
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>
                                            <Link to='/bunding' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                Bundling Machine
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>
                                            <Link to='/security' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                Security Alarm System
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>
                                            <Link to='/biometric' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                Biometric Machine
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>
                                            <Link to='/electronicsDis' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                Electronic Display Board
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>
                                            <Link to='/cctv' className="block px-4 py-2 text-base font-bold text-gray-600 hover:text-red-500 relative">
                                                CCTV (Closed Circuit Television)
                                                <span className="absolute left-0 bottom-0 h-1 bg-blue-100 w-full transition-all duration-300"></span>
                                            </Link>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </li>
                        <li className="md:mr-8">
                            <Link to='/contact' className="md:p-4 py-2 block text-gray-600 hover:text-red-500 text-lg font-bold">
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
