import React from 'react';
import logo from '../../img/Victor logo.png';
import Image from "../../img/Image.png"


function AboutComponent() {
  return (
    <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8  bg-cover ' style={{ backgroundImage: 'url(' + Image + ')' }} >
      <div className='flex flex-col lg:flex-row lg:items-center lg:space-x-8'>
        <div className='flex flex-col items-center lg:items-start'>
          <h1 className="text-red-600 font-bold text-center text-3xl sm:text-4xl lg:text-5xl mb-4 sm:mb-6 lg:mb-8">About Us</h1>
          <img src={logo} alt="Victor Enterprises Logo" className='w-48 h-48 lg:w-64 lg:h-64 mb-8' />
        </div>
        <div className='max-w-3xl mx-auto'>
          <div className='text-xl leading-relaxed'>
            <p className='mb-6'>
              Victor Enterprises was established in 2013 by Mr. Vikas Jagdale in Sangli. Our vision is to provide premium products and services to our clients. Our area of operation includes products like Cash Counting Machines, Gold Testing Machines, Security Alarm Systems, Biometric Machines, Electronic Display Boards, Notes Bundling Machines, and CCTVs. We aim to provide premium quality products.
            </p>
            <p className='mb-6'>
              Our highly trained and expert staff aims to provide the best sales and services for our products. Our 6 branches with well-stocked products provide you with the best services. Providing the best maintenance for all types of machines is another specialty. Our services come at the best competitive prices.
            </p>
            <p>
              Victor Enterprises products have a reputation for being the best and providing the best services after sales. That's why our products are preferred in various fields like banks, patasansthas, multistate societies, large finance companies, hospitals and medicals, jewellery shops, malls, supermarkets, retail shops, and even the hotel industry. Victor Enterprises is committed to providing our clients with the best products and services at the best prices in the industry. This makes our company the best option for your company to get your products from. Be a customer of Victor Enterprises and choose the best for your business!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutComponent;
