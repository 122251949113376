import React from 'react';
import hitachi from '../../../../img/hitachi.png';
import pocket from '../../../../img/pocket.png';
import cis1 from '../../../../img/cis1.png';
import cis2 from '../../../../img/cis2.png';
import st from '../../../../img/ST-300.png';

const SensorCountingMachine = () => {
    return (
        //bg-gradient-to-r from-gray-400 to-gray-200    
        <div className='container mx-auto px-4  '>
         <div className="flex flex-col md:flex-row md:items-center justify-center p-4">
              
                <div className="md:w-1/2 md:pl-8">
                    <div className="bg-white rounded-lg overflow-hidden shadow-md p-8">
                        <h2 className="text-xl font-semibold mb-2">Hitachi 1+1 Currency Fitness Sorter Machine (ST-300)</h2>
                        <p className="font-bold mb-2">Features</p>
                        <p>ST-300 is a smart, practical multi-currency currency sorter and banknote counter designed for user convenience. The ST-300 is optimized for high performance, enhanced authentication, and easy operation.</p>
                    </div>
                </div>
                <div className="">
                    <img src={st} className=" " alt="Hitachi 3+1 Pocket Sorter Machine (ST 350)" />
                </div>
            </div>
            <div className='flex flex-col md:flex-row md:items-center mb-8'>
                <div className='md:w-1/2 md:pr-8'>
                    <img src={hitachi} className='w-full mb-4 md:mb-0 md:w-auto md:max-w-xl' alt='Hitachi IH-210' />
                </div>
                <div className='md:w-1/2'>
                    <div className='bg-white rounded-lg overflow-hidden shadow-md p-8 mb-5'>
                        <h2 className='text-xl font-semibold mb-2'>Hitachi IH-210 2+1 Pocket Dual CIS</h2>
                        <p className='font-bold mb-2'>Features</p>
                        <ul className='list-disc pl-4 mb-4'>
                            <li>Compact Size with Full Function 3 Pockets</li>
                            <li>Similar size to 2 pockets machine</li>
                            <li>Saving workspace and minimizing cash processing movement</li>
                            <li>Selective pocket for fitness options: ATM, FIT, Reject</li>
                            <li>2+1 Pocket Dual CIS Sensor Counting Machine, 32 Currencies, 1,300 NPM Counting Speed, 1000 Notes</li>
                        </ul>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <img src={cis1} className='w-full md:w-72' alt='CIS1' />
                        <img src={cis2} className='w-full md:w-72' alt='CIS2' />
                    </div>


                </div>
            </div>
            <div className='flex flex-col md:flex-row md:items-center'>
                <div className='md:w-1/2 md:pl-8'>
                    <div className='bg-white rounded-lg overflow-hidden shadow-md p-8 mt-8'>
                        <h2 className='text-xl font-semibold mb-2'>Hitachi 3+1 Pocket Sorter Machine (ST 350)</h2>
                        <p className='font-bold mb-2'>Features</p>
                        <ul className='list-disc pl-4 mb-4'>
                            <li><span className='font-semibold'>High-speed image processing:</span> High resolution dual CIS, High level fitness sorting, Capture and Record of OCR, Can be installed up to 20 currencies</li>
                            <li><span className='font-semibold'>Advanced counterfeit detection:</span> Equipped with a range of counterfeit detection technologies.</li>
                            <li><span className='font-semibold'>ST-350 is an ideal choice for:</span> Bank (Cash Center), CIT (Cash in Transit)</li>
                        </ul>
                    </div>
                    <div className='bg-white rounded-lg overflow-hidden shadow-md p-8 mt-8'>
                        <h5 className='font-bold mb-2'>Product Features</h5>
                        <p>Fitness operation- (holes, tears, dog-ears, tape, soil, stains, folded, de-inked, graffiti etc.) Multiple pocket setting- (ATM, Fitness, dirt, reject / note direction(FF,FR,BF,BR) / note denomination) SIM program apply</p>
                    </div>
                </div>
                <div className='md:w-1/2'>
                    <img src={pocket} className='w-full mb-4 md:mb-0 md:w-auto md:max-w-xl' alt='Hitachi 3+1 Pocket Sorter Machine (ST 350)' />
                </div>
            </div>
          

        </div>
    );
}

export default SensorCountingMachine;



