import React from 'react'
import CCTV from './CCTV'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Contact/Footer'

function HomeCCTV() {
  return (
    <div className="mt-48 sm:mt-28">
    <Navbar/>
    <div className='mt-72 sm:mt-48'><CCTV/></div>
    <div ><Footer/></div>

  </div>
  )
}

export default HomeCCTV
