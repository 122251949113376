import React from 'react'
import HomeCarousal from './HomeCarousal'
import Footer from '../Contact/Footer'
import OurClient from '../Body/Client/OurClient'
import Repair from '../Body/OtherServices/Repair'
import AboutComponent from '../About/AboutComponent'
import NewProductList from '../Body/Whyus/NewProductList'

function Home() {
  return (
    <div className="mt-48 md:mt-0"> 
      <div className=''><HomeCarousal/></div>
      <div><AboutComponent/></div>
      <div><NewProductList /></div>
      <div><OurClient/></div>
      <div><Repair/></div>
      <div><Footer/></div>
    </div>
  )
}

export default Home
