import React from 'react';
import mainimg from '../../../img/hitachi.png';
import logo1 from '../../../img/logo1.png';
import logo2 from '../../../img/logo2.webp';
import logo3 from '../../../img/logo3.png';
import logo4 from '../../../img/logo4.png';
import { Link } from 'react-router-dom';
import HomeCash from './HomeCash';

function CashCount() {
    return (
<div className=" mx-auto px-4 py-8 bg-gray-100  ">
            <h1 className="text-5xl text-center font-bold mb-8 text-rose-800">Cash Counting Machine</h1>
            <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className="lg:w-1/2 lg:pr-4 mb-4 lg:mb-0 flex flex-col items-center">
                    <img src={mainimg} alt="Cash counting machine" className="w-full " />
                    <Link to='/cashform'>
                    <button className="bg-blue-500 hover:bg-blue-700 w-full lg:w-96 text-white font-bold py-2 px-4 rounded mt-2">Enquiry Now</button>
                    </Link>
                </div>



                <div className="lg:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specification</h3>
                        <table className="w-full text-left">
                            <tbody>
                                <tr>
                                    <th className="font-semibold">Hopper capacity</th>
                                    <td>200-300 Notes</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Counting Speed</th>
                                    <td>More and equal to 1000pcs/min</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Power Consumption</th>
                                    <td>Less than equal to 80W</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Power Supply</th>
                                    <td>AC220V, 50Hz</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Weight</th>
                                    <td>5.3kg to 6kg</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Automation Grade</th>
                                    <td>Automatic</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Batch Display</th>
                                    <td>3 digits</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Counting Display</th>
                                    <td>4 digits</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Size of Countable Note</th>
                                    <td>50x110-90x190mm</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                        <ul className="list-disc pl-4 text-left">
                            <li>High precision Advanced counterfeit detection- UV,MG,IR,3D color with size detection</li>
                            <li>Mixed note counting, Value count and denomination sorting in the same device</li>
                            <li>Easy firmware upgradation</li>
                            <li>Additional customer display as optional</li>
                            <li>Detects and counts both old and new currencies</li>
                            <li>Has automatic count mode</li>
                            <li>Easy maintenance: Self check function</li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className="mt-8 flex justify-evenly gap-4">
                <div className="flex flex-col items-center">
                    <img src={logo1} alt="" className="w-20 h-20 mb-2" />
                    <p className="text-center font-bold">Latest Technologies</p>
                </div>
                <div className="flex flex-col items-center">
                    <img src={logo2} alt="" className="w-20 h-20 mb-2" />
                    <p className="text-center font-bold">Top Brands</p>
                </div>
                <div className="flex flex-col items-center">
                    <img src={logo3} alt="" className="w-20 h-20 mb-2" />
                    <p className="text-center font-bold">Best Prices</p>
                </div>
                <div className="flex flex-col items-center">
                    <img src={logo4} alt="" className="w-20 h-20 mb-2" />
                    <p className="text-center font-bold">Wide Assortment</p>
                </div>
            </div>
            <div className='mt-10'>
                <HomeCash/>
            </div>

        </div>
    )
}

export default CashCount;
