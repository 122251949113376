import React from 'react';
import mainimg from '../../../img/Repair.webp';
import img1 from '../../../img/Repair-service.png.webp';
import img2 from '../../../img/Repair-product-quality.png.webp';
import img3 from '../../../img/Repair-product-warranty.png.webp';

function Repair() {
    return (
        <div className="bg-red-600 p-8 flex">
            <div>
                <div className="max-w-5xl mx-auto grid  ">
                    <div className="text-center lg:text-left">
                        <h1 className="text-4xl font-bold text-white mb-2">Note Counting Machine Repair</h1>
                        <h2 className="text-2xl font-semibold text-white mb-4">Reliable & Trusted</h2>
                        <p className="text-lg text-white">We repair note counting machines of any brand with great care. We use genuine spare parts for any replacements. Furthermore, we also provide AMC services on any kind of currency counting machines at low cost. Our service is trusted by various banks and business owners.</p>
                    </div>
                    <div className="lg:hidden">
                        <img src={mainimg} alt="Repair Main" className="w-full h-auto rounded-lg shadow-md" />
                    </div>
                </div>

                <div className="max-w-2xl grid gap-8 mt-8 ">
                    <div className="flex bg-white p-6 rounded-lg shadow-md items-center">
                        <img src={img1} alt="Door Step Service" className="w-20 h-20 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold">Door Step Service</h3>
                            <p className="text-gray-700">We provide door step service in all locations of Maharashtra & Karnataka. You just have to call us.</p>
                        </div>
                    </div>
                    <div className="flex bg-white p-6 rounded-lg shadow-md items-center">
                        <img src={img2} alt="Genuine Product" className="w-20 h-20 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold">100% Genuine Product</h3>
                            <p className="text-gray-700">Our note counting machines are strictly manufactured according to RBI guidelines for Indian Currencies.</p>
                        </div>
                    </div>
                    <div className="flex bg-white p-6 rounded-lg shadow-md items-center">
                        <img src={img3} alt="Warranty & AMC" className="w-20 h-20 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold">Warranty & AMC</h3>
                            <p className="text-gray-700">We provide 1 Year warranty on our products. AMC is also available for uninterrupted service at low cost.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 hidden lg:block">
                <img src={mainimg} alt="Repair Main" className="w-full h-auto " />
            </div>
        </div>
    );
}

export default Repair;
