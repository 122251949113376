import React, { useState } from 'react';
import BundalNote from './SubCashCount/BundalNote';
import BasicLoose from './SubCashCount/BasicLoose';
import MixValue from './SubCashCount/MixValue';
import CISCurrency from './SubCashCount/CISCurrency';
import SensorCountingMachine from './SubCashCount/SensorCountingMachine';

function HomeCash() {
  const [selectedOption, setSelectedOption] = useState('bundal');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className=" mx-auto py-8">
      <div className="flex flex-wrap justify-center gap-2">
        <button
          onClick={() => handleOptionChange('bundal')}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${selectedOption === 'bundal' ? 'bg-blue-700' : ''}`}
        >
          Bundal Note Counting Machine (BNC)
        </button>
        <button
          onClick={() => handleOptionChange('basic')}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${selectedOption === 'basic' ? 'bg-blue-700' : ''}`}
        >
          Basic Loose Note Counting Machine (LNC)
        </button>
        <button
          onClick={() => handleOptionChange('mix')}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${selectedOption === 'mix' ? 'bg-blue-700' : ''}`}
        >
          Mix Value Note Counting Machine (LNC)
        </button>
        <button
          onClick={() => handleOptionChange('cis')}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${selectedOption === 'cis' ? 'bg-blue-700' : ''}`}
        >
          CIS Currency Value
        </button>
        <button
          onClick={() => handleOptionChange('sensor')}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${selectedOption === 'sensor' ? 'bg-blue-700' : ''}`}
        >
          Currency Fitness Sorting Machine        </button>
      </div>
      <div className="mt-8">
        {selectedOption === 'bundal' && <BundalNote />}
        {selectedOption === 'basic' && <BasicLoose />}
        {selectedOption === 'mix' && <MixValue />}
        {selectedOption === 'cis' && <CISCurrency />}
        {selectedOption === 'sensor' && <SensorCountingMachine />}
      </div>
    </div>
  );
}

export default HomeCash;
