import React from 'react';
import mainimg1 from '../../../img/digital_bord.png';
import mainimg2 from '../../../img/digital-bord2.png';

import { Link } from 'react-router-dom';

function ElectronicsDis() {
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-100">
            <h1 className="text-5xl text-center font-bold mb-8 text-rose-800">Electronic Display Board</h1>
            <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className="lg:w-1/2 lg:pr-4 mb-4 lg:mb-0 flex flex-col items-center">
                    <img src={mainimg1} alt="Cash counting machine" className="w-64 h-96 " />
                    <img src={mainimg2} alt="Cash counting machine" className="w-64 h-96" />

                    <Link to='/cashform'>
                        <button className="bg-blue-500 hover:bg-blue-700 w-full lg:w-auto lg:w-96 text-white font-bold py-2 px-4 rounded mt-2">Enquiry Now</button>
                    </Link>
                </div>



                <div className="lg:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specification</h3>
                        <table className="w-full text-left">
                            <tbody>
                                <tr>
                                    <th className="font-semibold">Lighting Type	</th>
                                    <td>LED</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Viewing Distance</th>
                                    <td>100 Meter</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Text Color	</th>
                                    <td>Single & Multicolor</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Pixel Pitch	</th>
                                    <td>Customized</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Warranty	</th>
                                    <td>1 Year</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                        <ul className="list-disc pl-4 text-left">
                            <li>Display of interest rate, time & date</li>
                            <li>Extremely useful for Banks</li>
                            <li>No of lines 12-16</li>
                            <li>Data Upload Through Mobile Application</li>

                        </ul>
                    </div>
                    <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Description</h3>
                        <p>Interest Rate Display Board are ideal for banks. Date, time, deposit period, interest rate is displayed on an attractive background. Colour schemes, design,logo can be customised.</p>
                    </div>

                </div>

            </div>


        </div>
    )
}

export default ElectronicsDis;
