import React from 'react';
import mainimg from '../../../img/biomatric1.png';
import mainimg2 from '../../../img/biomatric2.png';

import logo1 from '../../../img/logo1.png';
import logo2 from '../../../img/logo2.webp';
import logo3 from '../../../img/logo3.png';
import logo4 from '../../../img/logo4.png';
import { Link } from 'react-router-dom';

function Biometric() {
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-100">
            <h1 className="text-3xl md:text-5xl text-center font-bold mb-8 text-rose-800">Biometric Machine</h1>
            <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className="lg:w-1/2 lg:pr-4 mb-4 lg:mb-0 flex flex-col items-center">
                    <img src={mainimg} alt="Cash counting machine" className="w-full rounded-lg h-auto mb-4 lg:mb-0" />
                    <img src={mainimg2} alt="Cash counting machine" className="w-full rounded-lg h-auto mb-4 lg:mb-0" />
                    <Link to='/cashform'>
                    <button className="bg-blue-500 hover:bg-blue-700 w-full lg:w-auto lg:w-96 text-white font-bold py-2 px-4 rounded mt-2">Enquiry Now</button>

                    </Link>
                </div>
                <div className="lg:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specification</h3>
                        <table className="w-full text-left">
                            <tbody>
                                <tr>
                                    <th className="font-semibold">Face Capacity</th>
                                    <td>1000</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Fingerprint Capacity </th>
                                    <td>1000</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Card Capacity</th>
                                    <td>1000</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Password Capacity </th>
                                    <td>1000</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Max Attendance log</th>
                                    <td>5.3kg to 6kg</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Automation Grade</th>
                                    <td>100,000</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Identify mode</th>
                                    <td>Face/Fingerprint/Card/Password</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Display Language</th>
                                    <td>English</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Communication</th>
                                    <td>TCP/IP, USB Disk, Wifi, 4G(Optional)</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Access Control</th>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Battery</th>
                                    <td>Buid in Battery Backup</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Working Voltage</th>
                                    <td>DC 12V</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Working Temperature</th>
                                    <td>-10c ~ +50c</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Intelligence Adaptation Function</th>
                                    <td>Support</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Matching Mode</th>
                                    <td>Support 1:N</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Card Reader Connectivity</th>
                                    <td>Yes On Weight In </td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">QA Reader Support</th>
                                    <td>Support On Weight IN(Yes) </td>
                                </tr> <tr>
                                    <th className="font-semibold">Push Data</th>
                                    <td>Yes, Cloud Support</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                        <ul className="list-disc pl-4 text-left">
                            <li>Face Recognition</li>
                            <li>Fingerprint</li>
                            <li>RF Card</li>
                            <li>TCP/IP</li>
                            <li>Personal Identity Number</li>
                            <li>USB Disk</li>
                            <li>Access Control</li>
                            <li>Camera</li>
                            <li>Fingerprint Scanner</li>
                        </ul>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Biometric;
