import React from 'react';
import home from '../../img/CCTV.png';

function Homepage3() {
  return ( 
<div className=" sm:h-screen bg-center pt-10 bg-gradient-to-r from-gray-500 to-black">
      <section className="dark:bg-gray-100 dark:text-gray-800 bg-opacity-75">
        <div className="container flex flex-col justify-center  p-6 mx-auto sm:py-12 lg:py-24 xl:py-32 lg:flex-row lg:justify-between">
          <div className="flex flex-col justify-center mt-28 p-6 text-white text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
          <h1 className="text-5xl font-bold leading-none sm:text-6xl">Security Alarm System
            </h1>
            <p className="mt-6 mb-8 text-lg sm:mb-12">Protect What Matters Most with Our Security Alarm Systems
              <br className="hidden md:inline lg:hidden" />Your trusted partner in security solutions. Explore our state-of-the-art security alarm systems designed to safeguard your home or business. 
            </p>
            <div className="">
              <a rel="noopener noreferrer" href="#" className="inline-block px-6 py-3 text-lg font-semibold leading-none text-white bg-blue-500 rounded hover:bg-blue-600">Know More</a>
            </div>
          </div>
          <div className="flex items-center justify-center p-6 mt-2 lg:mt-0">
            <img src={home} alt="Cash Counting Machine" className="object-contain max-h-96 sm:max-h-full lg:max-h-96 xl:max-h-full 2xl:max-h-112" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Homepage3;
