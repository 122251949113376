import React from 'react'
import Navbar from '../Navbar/Navbar'
import ContactComponent from './ContactComponent'
import Footer from './Footer'

function Contact() {
  return (
    <div>
      <div className=''><Navbar/></div>
      <div className='mt-20'><ContactComponent/></div>
      <div className=''><Footer/></div>
    </div>
  )
}

export default Contact
