import React from 'react';
import './App.css';
import About from './Component/About/About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BundalNote from './Component/Product/Cash Counting/SubCashCount/BundalNote';
import BasicLoose from './Component/Product/Cash Counting/SubCashCount/BasicLoose';
import MixValue from './Component/Product/Cash Counting/SubCashCount/MixValue';
import CISCurrency from './Component/Product/Cash Counting/SubCashCount/CISCurrency';
import CashForm from './Component/Product/Cash Counting/CashForm';
import Home from './Component/Home/Home';
import CashCount from './Component/Product/Cash Counting/CashCount';
import GoldMachine from './Component/Product/Gold Testing/GoldMachine';
import Bundingmachine from './Component/Product/Bundling Machine/Bundingmachine';
import Biometric from './Component/Product/Biometric/Biometric';
import Security from './Component/Product/Security Alarm/Security';
import CCTV from './Component/Product/CCTV/CCTV';
import ElectronicsDis from './Component/Product/Electronic Display/ElectronicsDis';
import HomeBiomatric from './Component/Product/Biometric/HomeBiomatric';
import HomeCashCount from './Component/Product/Cash Counting/HomeCashCount';
import HomeGold from './Component/Product/Gold Testing/HomeGold';
import HomeBunding from './Component/Product/Bundling Machine/HomeBunding';
import HomeSecurity from './Component/Product/Security Alarm/HomeSecurity';
import HomeCCTV from './Component/Product/CCTV/HomeCCTV';
import HomeElectronicsDis from './Component/Product/Electronic Display/HomeElectronicsDis';
import Contact from './Component/Contact/Contact';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/bundal' element={<BundalNote />} />
          <Route path='/lose' element={<BasicLoose />} />
          <Route path='/mix' element={<MixValue />} />
          <Route path='/currency' element={<CISCurrency />} />
          <Route path='/cashform' element={<CashForm />} />
          <Route path='/cashcount' element={<HomeCashCount />} />
          <Route path='/goldmachine' element={<HomeGold />} />
          <Route path='/bunding' element={<HomeBunding />} />
          <Route path='/biometric' element={<HomeBiomatric />} />
          <Route path='/security' element={<HomeSecurity />} />
          <Route path='/cctv' element={<HomeCCTV />} />
          <Route path='/electronicsDis' element={<HomeElectronicsDis />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
