import React, { useState } from 'react';
import { MdLocalPhone, MdOutlineMail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from 'react-router-dom';

function UpperNav() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <>
            <div className='flex flex-col md:flex-row justify-between items-center p-4 md:px-8 bg-gray-700'>
            <div className='flex items-center space-x-2 md:space-x-4'>
                    <MdLocalPhone className='text-white text-xl font-bold' />
                    <p className='text-white text-lg font-bold'>+91 7709049044</p>
                </div>
                <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div>
                <div className='flex items-center space-x-2 md:space-x-4'>
                    <MdOutlineMail className='text-white text-xl font-bold' />
                    <p className='text-white text-lg font-bold'>victorent197@gmail.com</p>
                </div>
                <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div>
                <div className='sm:flex items-center space-x-2 md:space-x-8 hidden md:flex'>
                    <RiWhatsappFill className='text-white text-lg' />
                    <FaFacebookF className='text-white text-lg' />
                    <AiFillInstagram className='text-white text-lg' />
                    <FaTwitter className='text-white text-lg' />
                </div>
                <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div>
                <div className='flex items-center md:hidden'>
                    <button onClick={toggleNav} className='text-white focus:outline-none'>
                        <svg className='w-8 h-8' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            {isNavOpen ? (
                                <path d='M19 13H5v-2h14v2z' fill='currentColor' />
                            ) : (
                                <>
                                    <path d='M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z' fill='currentColor' />
                                </>
                            )}
                        </svg>
                    </button>
                </div>
                <div>
                    <Link to='/cashform'> 
                        <button className='hidden md:block bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded md:ml-4'>
                        Enquire Now                        </button>
                    </Link>
                    </div>
                    </div>
            {isNavOpen && (
                <div className='flex justify-evenly bg-gray-700'>
                <div className='md:hidden  flex'>
                    <div className='flex text-center gap-4 text-2xl items-center '>
                        <RiWhatsappFill className=' text-white' />
                        <FaFacebookF className='text-white' />
                        <AiFillInstagram className='text-white' />
                        <FaTwitter className='text-white' />
                    </div>
                </div>
                <div>
                    <Link to='/cashform'> 
                        <button className='md:hidden bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded md:ml-4 m-2 '>
                            Enquiry Now
                        </button>
                    </Link>
                    </div>
                </div>
            )}
            <hr
                className="w-full mx-auto"
                style={{
                    background: 'linear-gradient(to right, #ff9e5f,#ff0000, #ff9e5f)',
                    height: '4px',
                    border: 'none'
                }}
            />
      
    </>
    );
}

export default UpperNav;
