import React from 'react';
import img1 from '../../../../img/cash3 (2).png';
import img2 from '../../../../img/cash3.png';

function BasicLoose() {
  return (
    <div className=" mx-auto py-8 px-4  ">
      <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-rose-800">Basic Loose Note Counting Machine (LNC)</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center md:items-start justify-center">
          <img src={img1} alt="LT-150 (Bill Counter)" className="w-full md:w-96 h-64 object-cover object-center" />
          <div className="p-4 text-center md:text-left">
            <h2 className="text-xl font-semibold mb-2">LT-150 (Bill Counter)</h2>
            <p className="text-gray-600 mb-2">Ultraviolet (UV) and Magnetic (MG) Counterfeit Detection</p>
            <p className="text-gray-600 mb-2">Batching, adding and self-examination functions</p>
            <p className="text-gray-600">Speed: 1000 bills/min</p>
          </div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center md:items-start justify-center">
          <img src={img2} alt="LT-001 (Semi Value)" className="w-full md:w-96 h-64 object-cover object-center" />
          <div className="p-4 text-center md:text-left">
            <h2 className="text-xl font-semibold mb-2">LT-001 (Semi Value)</h2>
            <p className="text-gray-600 mb-2">Ultraviolet (UV) and Magnetic (MG) Counterfeit Detection</p>
            <p className="text-gray-600 mb-2">Batching, adding and self-examination functions</p>
            <p className="text-gray-600 mb-2">Double-optics Detecting with IR (Infrared ray) detection system</p>
            <p className="text-gray-600">Speed: 1000 bills/min</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicLoose;
