import React from 'react'
import CashCount from './CashCount'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Contact/Footer'

function HomeCashCount() {
  return (
    <div className="">
    <Navbar/>
   <div className='mt-72 sm:mt-48'> <CashCount/></div>
   <div ><Footer/></div>

  </div>
  )
}

export default HomeCashCount
