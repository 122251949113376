import React from 'react';
import logo from '../../img/Victor logo.png'
import { MdLocalPhone, MdOutlineMail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

function Footer() {
  const handleCall = () => {
    window.location.href = 'tel:+917709049044';
  };

  const handleGetSangliDirection = () => {
    window.open('https://www.google.com/maps/dir//16.852825,74.5808556/@16.8964857,74.5250228,12z?entry=ttu');
  };
  const handleGetKolhapurDirection = () => {
    window.open('https://www.google.com/maps/dir//16.7011534,74.2333727/@16.8569991,74.0590793,10z?entry=ttu');
  };
  return (
    <footer class="bg-white">
      <div class="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6  lg:px-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="text-teal-600">
            <img src={logo} alt="" className='h-20' />
          </div>

          <ul class="mt-8 flex justify-start gap-6 sm:mt-0 sm:justify-end">
            <li>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                class="text-gray-700 transition hover:opacity-75"
              >
                <span class="sr-only">Facebook</span>

                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                class="text-gray-700 transition hover:opacity-75"
              >
                <span class="sr-only">Instagram</span>

                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                class="text-gray-700 transition hover:opacity-75"
              >
                <span class="sr-only">Twitter</span>

                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                  />
                </svg>
              </a>
            </li>

            <li className='text-gray-700 transition hover:opacity-75'>
              <RiWhatsappFill className='h-6 w-6' />
            </li>



          </ul>
        </div>

        <div
          class="grid grid-cols-1 gap-8 border-t border-gray-200 pt-8 sm:grid-cols-2 lg:grid-cols-4 "
        >
          <div>
            <p class="font-bold text-xl text-red-900">Our Branches</p>

            <ul class="mt-6 space-y-2 text-sm">
              <li>
                <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75">Sangli</a>
              </li>

              <li>
                <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Kolhapur  </a>
              </li>

              <li>
                <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Ichalkaranji </a>
              </li>

              <li>
                <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Karad </a>
              </li>

              <li>
                <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Satara </a>
              </li>

              <li>
                <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Solapur </a>
              </li>
            </ul>
          </div>

          <div>
            <p class="font-bold text-xl text-red-900">Our Product</p>

            <ul class="mt-6 space-y-2 text-sm">
              <li>
                <Link to='/cashcount'>    <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Cash Counting Machine </a></Link>
              </li>

              <li>
                <Link to='/goldmachine'>    <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Gold Testing Machine</a></Link>
              </li>

              <li>
                <Link to='/security'> <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Security Alarm System </a></Link>
              </li>
              <li>
                <Link to='/biometric'> <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Biometric Machine </a></Link>
              </li>

              <li>
                <Link to='/electronicsDis'>  <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Electronic Display Board </a></Link>
              </li>

              <li>
                <Link to='/bunding'>  <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Bundling Machine </a></Link>
              </li>

              <li>
                <Link to='/cctv'>   <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> CCTV (Closed Circuit Television) </a></Link>
              </li>
            </ul>
          </div>

          <div className=''>
            <p class="font-bold text-xl text-red-900 ">Helpful Links</p>

            <ul class="mt-6 space-y-2 ">
              <li>
                <Link to='/'>  <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Home </a></Link>
              </li>

              <li>
                <Link to='/about'>   <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> About </a></Link>
              </li>

              <li>
                <Link to='/cashcount'> <a href="#" class="text-gray-700 font-medium text-lg transition hover:opacity-75"> Product </a></Link>
              </li>


            </ul>
          </div>

          <div className=''>
            <p className="font-bold text-xl text-red-900">Contact</p>
            <ul className="mt-6 space-y-4 text-sm">
              <li className=''>
                <a href="tel:+917709049044" className="text-gray-700 font-medium text-lg transition hover:opacity-75 flex items-center">
                  <MdLocalPhone className="mr-2" /> +91 7709049044
                </a>
              </li>
              <li className=''>
                <a href="https://wa.me/917757009999" className="text-gray-700 font-medium text-lg transition hover:opacity-75 flex items-center">
                  <RiWhatsappFill className="mr-2" /> +91 7757009999
                </a>
              </li>
              <li className=''>
                <a href="mailto:victorent197@gmail.com" className="text-gray-700 font-medium text-lg transition hover:opacity-75 flex items-center">
                  <MdOutlineMail className="mr-2" /> victorent197@gmail.com
                </a>
              </li>
            </ul>
          </div>



        </div>
        <div className=' '>
          <div className='flex justify-center text-center'>
            <h1 className='font-bold text-xl text-red-900'>Address</h1>
          </div>
          <section className='flex flex-col sm:flex-row sm:justify-between items-start'>

            <address className='not-italic mb-6 sm:mb-0 sm:mr-8 p-4 bg-white'>
              <h2 className='font-semibold text-xl text-gray-800 mb-2'>Sangli Office</h2>
              <p className='text-gray-700 font-medium text-lg'>6, Mahalaxmai Complex, Behind Sangli DCC Bank, Pushpraj Chowk, Sangli</p>
              <div className=' mt-6'>
                <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-4' onClick={handleCall}>
                  Call
                </button>
                <button className='bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded' onClick={handleGetSangliDirection}>
                  Get Direction
                </button>
              </div>

            </address>
            <address className='not-italic p-4 bg-white '>
              <h2 className='font-semibold text-xl text-gray-800 mb-2'>Kolhapur Office</h2>
              <p className='text-gray-700 font-medium text-lg'>745, Nalini Complex Shop No.3, Nr.Govt, Servant Bank, Shahupuri 3rd Lane, Kolhapur</p>
              <div className=' mt-6'>
                <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-4' onClick={handleCall}>
                  Call
                </button>
                <button className='bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded' onClick={handleGetKolhapurDirection}>
                  Get Direction
                </button>
              </div>
            </address>
          </section>
        </div>
        <p class="text-xs text-gray-500 font-medium md:text-lg">&copy;  2024 Vector Enterprises All Rights Reserved To <a href='https://www.businessmantra.info/index.php' className='text-blue-800'>Business Mantra.</a> </p>
      </div>
    </footer>
  );
}

export default Footer;
