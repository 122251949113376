import React from 'react'
import Bundingmachine from './Bundingmachine'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Contact/Footer'

function HomeBunding() {
  return (
    <div className="mt-48 sm:mt-28">
      <Navbar/>
      <div className='mt-72 sm:mt-48'>       <Bundingmachine/></div>
      <div ><Footer/></div>

      
    </div>
  )
}

export default HomeBunding
