import React from 'react';
import img1 from '../../../../img/Cash1.png';
import img2 from '../../../../img/Cash1(1).png';

function BundalNote() {
  return (
    <div className=" mx-auto py-8 px-4">
      <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-rose-800">Bundal Note Counting Machine (BNC)</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
          <img src={img1} alt="VNCM-01 (Floor Top)" className="w-full md:w-64 h-64 object-cover object-center" />
          <div className="p-4 text-center md:text-left">
            <h2 className="text-xl font-semibold mb-2">VNCM-01 (Floor Top)</h2>
            <p className="text-gray-600 mb-2">VNCM-01-FT (Heavy Duty)</p>
            <p className="text-gray-600 mb-2">UV (Plain Paper) Detection Optional</p>
            <p className="text-gray-600">Counting Speed 100 pcs/4 sec.</p>
          </div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
          <img src={img2} alt="VNCM-02 (Desk Top)" className="w-full md:w-full h-64 object-cover object-center" />
          <div className="p-4 text-center md:text-left">
            <h2 className="text-xl font-semibold mb-2">VNCM-02 (Desk Top)</h2>
            <p className="text-gray-600 mb-2">VNCM-02-DT (Heavy Duty)</p>
            <p className="text-gray-600 mb-2">UV (Plain Paper) Detection Optional</p>
            <p className="text-gray-600">Counting Speed 100 pcs/4 sec.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BundalNote;
