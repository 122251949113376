import React from 'react';
import mainimg from '../../../img/Bundling Machine_body.png';
import { Link } from 'react-router-dom';
import bmachine from '../../../img/Shrink.png';
import bundle from '../../../img/Cash_bundlepack.png';

function Bundingmachine() {
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-100">
            <h1 className="text-5xl text-center font-bold mb-8 text-rose-800">Bundling Machine</h1>
            <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className="lg:w-1/2 lg:pr-4 mb-4 lg:mb-0 flex flex-col items-center">
                <p className='text-3xl text-center font-bold mb-8 text-rose-800'>Strapping Machine</p>

                    <img src={mainimg} alt="Bundling machine" className="w-full " />
                    <Link to='/cashform'>
                    <button className="bg-blue-500 hover:bg-blue-700 w-full  lg:w-96 text-white font-bold py-2 px-4 rounded mt-2">Enquiry Now</button>
                    </Link>
                </div>

                <div className="lg:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specifications</h3>
                        <table className="w-full text-left">
                            <tbody>
                                <tr>
                                    <th className="font-semibold">Mix. Strap Size (mm)</th>
                                    <td>Not Stipulated</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Min. Strap Size (mm)</th>
                                    <td>60 x 30</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Strapping Speed (Seconds)</th>
                                    <td>1.5</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Strap Width (mm)</th>
                                    <td>615</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Strap Force (Newton)</th>
                                    <td>450</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Working Table Height (mm)</th>
                                    <td>750</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Power Source (V/Hz)</th>
                                    <td>220/50, 1/4HP</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">External Dimension (mm)</th>
                                    <td>900x580x750</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold">Weight (kg Approx.)</th>
                                    <td>105</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-8 bg-white  p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                        <p className=''>
                            Honey Copper wire Motor, instant heating in only 20 seconds, magnetic drive for faster speed packing, list type top cover allows convenient machine, economical design of control switch, independence PCB Control feeding and heating, the unit is easy to maintain. One-touch machine operation, energy-saving mode activated within 1.5 sec of non-use, and allows for instant operation. Stainless steel frame is available for strapping moisturized products.
                        </p>
                    </div>
                    <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Description</h3>
                        <p className=''>
                            Semi-automatic box strapping machine uses polythene plastic strapping as a binder around corrugated carton, wooden crates as well as parcels in all types of industry. A fast, efficient machine guaranteed to save time and money. The automatic strapping machine, thermal sealing as well as auto tension & cutting Coupled With full stopping operation makes the Heavy Model an operator's pleasure.
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-8 flex flex-col md:flex-row items-center justify-evenly'>
                <div className=' bg-white  p-6 rounded-lg shadow-md'>
                    <h1 className='text-center text-2xl font-bold mb-4 text-rose-800'>Shrink Wrapping Machine</h1>
                    <table className="w-full text-left">
                        <tbody>
                            <tr>
                                <th className="font-semibold">Machine Dimension</th>
                                <td>36*18*28</td>
                            </tr>
                            <tr>
                                <th className="font-semibold">Chamber Dimension</th>
                                <td>18*8*6</td>
                            </tr>
                            <tr>
                                <th className="font-semibold">Power</th>
                                <td>2 phase +1 N</td>
                            </tr>
                            <tr>
                                <th className="font-semibold">Conveyor Motor</th>
                                <td>DC motor 24 V</td>
                            </tr>
                            <tr>
                                <th className="font-semibold">Conveyor Speed</th>
                                <td>Variable</td>
                            </tr>
                            <tr>
                                <th className="font-semibold">Heater Watt</th>
                                <td>3750 W</td>
                            </tr>
                            <tr>
                                <th className="font-semibold">Blower Motor</th>
                                <td>0.18 hp single phase 2800 RPM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <img src={bmachine} alt="Shrink Wrapping Machine" className='w-96 md:w-96 h-96  mb-4 md:mr-8' />

            </div>

            <div className='mt-8 flex flex-col md:flex-row items-center '>
                <img src={bundle} alt="Banding Machine" className='w-96 md:w-full h-auto  mb-4 md:ml-8' />
                <div className="md:w-full">
                    <h1 className='text-center text-2xl font-bold mb-4 text-rose-800'>Banding Machine</h1>
                    <div className='mb-4 mt-8 bg-white  p-6 rounded-lg shadow-md'>
                        <h3 className='text-2xl font-bold mb-4 text-rose-800'>Features</h3>
                        <ul className="list-disc list-inside">
                            <li className='mb-2'>Full-automatic and Quick Banding: The Note Strip Binding Machine provides quick and seamless banding of 100 notes at once, significantly reducing processing time.</li>
                            <li className='mb-2'>Optional Stamping: The machine can be equipped with a stamping feature (optional), further enhancing the security and organization of bound notes.</li>
                            <li className='mb-2'>Easy Operation: With its microcomputer control, the machine offers simple and intuitive operation, making it easy for anyone to use.</li>
                        </ul>
                    </div>
                    <div className='mt-8 bg-white  p-6 rounded-lg shadow-md'>
                        <h3 className='text-2xl font-bold mb-4 text-rose-800'>Description</h3>
                        <p className=''>
                            Introducing the Note Strip Binding Machine by Kavinstar, an essential tool for banks seeking efficiency and professionalism in their note bundling process. This fully automatic machine streamlines the tedious task of binding and stamping notes with paper tape. Its user-friendly interface and LED display make operating a breeze for any bank personnel.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Bundingmachine;
