import React from 'react';
import { Link } from 'react-router-dom';
import Image from "../../../img/Image.png"


// Define your product data
const products = [
  {
    img: require('../../../img/Cash2 (2).png'),
    name: "Cash Counting Machine",
    link: "/cashcount"
  },
  {
    img: require('../../../img/Bundling Machine.jpg'),
    name: "Bundling Machine",
    link: "/bunding"
  },
  {
    img: require('../../../img/System-product.jpg'),
    name: "Security Alarm System",
    link: "/security"
  },
  {
    img: require('../../../img/goldtesting-product.jpg'),
    name: "Gold Testing Machine",
    link: "/goldmachine"
  },
 
  {
    img: require('../../../img/Biomatric-product.png'),
    name: "Biometric Machine",
    link: "/biometric"
  },
  {
    img: require('../../../img/digital-bord2.png'),
    name: "Electronic Display Board",
    link: "/electronicsDis"
  },
 
  {
    img: require('../../../img/cctv-product.jpg'),
    name: "CCTV (Closed Circuit Television)",
    link: "/cctv"
  }
];

function NewProductList() {
  return (
    <div className='w-full text-center py-16 bg-current bg-cover' style={{ backgroundImage: 'url(' + Image + ')' }}>
      <h1 className='text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-red-600 from-40%  to-black  italic tracking-widest'>Products</h1>
      <h1 className='text-xl font-semibold italic'>(Sales & Services)</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 py-10 ">
        {products.map((product, index) => (
          <div key={index} className="w-80 h-96 py-5 bg-white rounded-2xl shadow-lg hover:shadow-black mx-auto ">
            <img className="w-60 h-60 mx-auto rounded-xl" src={product.img} alt="" />
            <div className="px-2 py-4">
              <h1 className=" text-lg font-bold tracking-wider">{product.name}</h1>
              <Link to={product.link}>
                <button className="w-40 mt-2 text-white text-sm font-semibold bg-[#0A79DF] py-2 px-4 rounded-md shadow-md hover:shadow-lg transition duration-300 transform-gpu hover:scale-110">See More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewProductList;
