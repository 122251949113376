import React from 'react';
import mainimg from '../../../img/alarm-system.png';
import protect from '../../../img/3_Zone_with_GSM.png'
import { Link } from 'react-router-dom';
import img1 from '../../../img/protect1.png';
import img2 from '../../../img/protect2.png';
import img3 from '../../../img/protect3.png';

function Security() {
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-100">
            <h1 className="text-5xl text-center font-bold mb-8 text-rose-800">Security Alarm System</h1>

            <div className="flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-8">
                <div className="lg:w-1/2 lg:pr-4 mb-8 lg:mb-0 flex flex-col items-center">
                    <img src={mainimg} alt="Cash counting machine" className="w-full transition-transform duration-200 hover:scale-105" />
                    <Link to='/cashform' className="mt-4 w-full lg:w-auto">
                        <button className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded mt-2 transition-colors duration-200">Enquiry Now</button>
                    </Link>
                </div>

                <div className="lg:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md mb-4">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specification</h3>
                        <table className="w-full text-left">
                            <tbody>
                                <tr>
                                    <th className="font-semibold py-2">Connectivity Type</th>
                                    <td>Wireless</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Usage/Application</th>
                                    <td>Home</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Model Name/Number</th>
                                    <td>MSA-WLG19</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Brand</th>
                                    <td>Mayur</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Material</th>
                                    <td>ABS</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Sound Level</th>
                                    <td>110 dB</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Built In Battery</th>
                                    <td>3.7 High Capacity LI Battery</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                        <ul className="list-disc pl-4 text-left space-y-2">
                            <li>Wireless Control Panel with TFT Screen, Built-in GSM Dialer</li>
                            <li>99 Wireless Zones with Auto Arming/Disarming</li>
                            <li>Supports 150 detectors, 150 remote control.</li>
                            <li>Control with Android/IOS App, SMS message, Phone Call, RF Remote Control.</li>
                            <li>Low battery notification and anti-tamper function of control panel and sensor</li>
                            <li>GSM dialer- Calling with 10 seconds voice message with zone wise notification and SMS Message on 8 mobile numbers</li>
                            <li>Built-in rechargeable battery, auto switch upon power failure</li>
                            <li>SMS message notify user once power cut.</li>
                            <li>Built-in siren, 7 levels voice volume adjustable, compatible with external wireless/ wired siren.</li>
                            <li>Event log of recent 99 alarm records, 99 disarmed records</li>
                            <li>Programmable Four Wired Zone NO/ NC Type</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mt-20 items-center md:space-x-8 justify-evenly">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h3 className="text-2xl font-bold mb-4 text-rose-800">PROTECTOR ULTRA GSM Alarm System</h3>
                    <table className="w-full text-left">
                        <tbody>
                            <tr>
                                <th className="font-semibold py-2">Type</th>
                                <td>Wired + Wireless</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Model Name/Number</th>
                                <td>MSA Protector Ultra</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Material</th>
                                <td>MS</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Alarm Range</th>
                                <td>400mtr to 500mtr</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Color</th>
                                <td>Grey</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Usage/Application</th>
                                <td>Suitable for Home, Shop, Office & Banks</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Power Source</th>
                                <td>12V</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Weight</th>
                                <td>1.5kg</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Voltage</th>
                                <td>12V</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Sound Level</th>
                                <td>15W</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Battery</th>
                                <td>12V/7Ah</td>
                            </tr>
                            <tr>
                                <th className="font-semibold py-2">Power Supply</th>
                                <td>12V</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <img src={protect} alt="PROTECTOR ULTRA GSM Alarm System" className="w-96 h-auto mb-4 transition-transform duration-200 hover:scale-105" />
            </div>

            <div className="mt-8 bg-white p-6 rounded-lg shadow-md mb-4">
                <p className="mb-4">Intelligent 2 Zone Hybrid (Wired and wireless) Control Panel with GSM Auto Dialer, One wired Zone with Programmable Delay and second 24Hrs Emergency zone, ARM/ DISARM through RF Remote, text SMS & Mobile APP, Wired/Wireless Door/Shutter/ PIR/ Vibration/Smoke Can be Program, Total 24 Nos. Wireless remote and sensors can be program.</p>
            </div>

            <div className="mt-8">
                <h1 className="text-4xl font-bold mb-6 text-center text-rose-800">Intelligent Security Alarm System (Non GSM)</h1>
                <div className="grid gap-8 lg:grid-cols-3">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <img src={img1} alt="PROTECTOR CORE" className="w-full h-auto  mb-4 transition-transform duration-200 hover:scale-105" />
                        <div>
                            <h1 className="text-2xl font-bold mb-2 text-rose-800">PROTECTOR CORE</h1>
                            <p className="mb-4">Intelligent 2 Zone Alarm Control Panel</p>
                            <ul className="list-disc pl-4 space-y-2">
                                <li>Arm/ disarm through key lock</li>
                                <li>1st Delay Zone & 2nd is 24Hrs Panic Zone</li>
                                <li>Easy to program entry, exit and siren time</li>
                                <li>Automatic rearming after power resume</li>
                                <li>LED indications for zone & power status</li>
                                <li>In built battery backup provision up to 7Ah</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <img src={img2} alt="PROTECTOR DELTA - 4G LTE" className="w-full h-auto  mb-4 transition-transform duration-200 hover:scale-105" />
                        <div>
                            <h1 className="text-2xl font-bold mb-2 text-rose-800">PROTECTOR DELTA - 4G LTE</h1>
                            <p className="mb-4">Intelligent 3 Zone Control Panel with 4G LTE GSM Dialer</p>
                            <ul className="list-disc pl-4 space-y-2">
                                <li>Controlled by Key pad, Mobile App & Remote</li>
                                <li>1st Delay Zone, 2nd Immediate Zone & 3rd 24 Hrs Panic Zone</li>
                                <li>SMS alert facility, can monitor Panel Status</li>
                                <li>Calling on 10 Numbers & SMS on 5 Numbers</li>
                                <li>LED indications for System, Zone & Power Status</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <img src={img3} alt="PSTN / GSM DIALER" className="w-full h-auto  mb-4 transition-transform duration-200 hover:scale-105" />
                        <div>
                            <h1 className="text-2xl font-bold mb-2 text-rose-800">PSTN / GSM DIALER</h1>
                            <p className="mb-4">Stand Alone GSM Dialer</p>
                            <ul className="list-disc pl-4 space-y-2">
                                <li>Onboard digital keypad for programming & status Indication</li>
                                <li>Two trigger input, one NO/NC type & one 12V DO</li>
                                <li>Two type trigger mode, level & pulse</li>
                                <li>20 Sec. re-recordable voice for each trigger input</li>
                                <li>20 Tel/mobile number can be programmed, SMS alert facility</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security;
