import React from 'react'
import Security from './Security'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Contact/Footer'

function HomeSecurity() {
  return (
    <div className="mt-48 sm:mt-28">
      <Navbar/>
      <div className='mt-72 sm:mt-48'>      <Security/></div>
      <div ><Footer/></div>

      
    </div>
  )
}

export default HomeSecurity
