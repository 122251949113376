import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import Homepage1 from './Homepage1';
import Homepage2 from './Homepage2';
import Homepage3 from './Homepage3';
import Homepage4 from './Homepage4';
import Homepage5 from './Homepage5';
import Homepage6 from './Homepage6';
import Homepage7 from './Homepage7';
import Navbar from '../Navbar/Navbar';



function HomeCarousal() {
    const [isMouseOver, setIsMouseOver] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        beforeChange: () => setIsMouseOver(true),
        afterChange: () => setIsMouseOver(false),
    };

    return (
        <div>

            <Navbar  />
           
            <Slider  {...settings} onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
                <div className=''><Homepage1 /></div>
                <div><Homepage2/></div>
                <div><Homepage3/></div>
                <div><Homepage4/></div>
                <div><Homepage5/></div>
                <div><Homepage6/></div>
                <div><Homepage7/></div>
            </Slider>
        </div>
    );
}

export default HomeCarousal;
