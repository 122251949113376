import React from 'react'
import UpperNav from '../../Navbar/UpperNav'
import Navbar from '../../Navbar/Navbar'
import Biometric from './Biometric'
import Footer from '../../Contact/Footer'

function HomeBiomatric() {
  return (
    <div className="mt-48 sm:mt-28">
      <Navbar/>
      <div className='mt-72 sm:mt-48'>        <Biometric/></div>
      <div >        <Footer/></div>


    </div>
  )
}

export default HomeBiomatric
