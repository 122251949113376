import React from 'react'
import ElectronicsDis from './ElectronicsDis'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Contact/Footer'

function HomeElectronicsDis() {
  return (
    <div className="mt-48 sm:mt-28">
      <Navbar/>
      <div className='mt-72 sm:mt-48'><ElectronicsDis/></div>
      <div ><Footer/></div>

    </div>
  )
}

export default HomeElectronicsDis
