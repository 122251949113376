import React from 'react';
import img1 from '../../../../img/Cash2.png';
import img2 from '../../../../img/Cash2 (2).png';
import img3 from '../../../../img/Cash2 (3).png';
import img4 from '../../../../img/cash2 (4).png';
import img5 from '../../../../img/cash2 (5).png';
import img6 from '../../../../img/cash2 (6).png';

function MixValue() {
    return (
        <div className=" mx-auto py-8 px-4">
            <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-rose-800">Mix Value Note Counting Machine (LNC)</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img1} alt="Rich Class White" className="w-full md:w-full h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Rich Class White</h2>
                        <p className="text-gray-600 mb-2">Mix Note Value Counter cum Fake note Detection Machine with Customer Display</p>
                    </div>
                </div>

                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img2} alt="Lotus Golden (Double Display)-LT-1000" className="w-full md:w-full h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Lotus Golden (Double Display)-LT-1000</h2>
                        <p className="text-gray-600 mb-2">Mix Note Value Counter cum Fake note Detection Machine with Customer Display</p>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img3} alt="Lotus Golden (Single Display)-LT-1500" className="w-full md:w-full h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Lotus Golden (Single Display)-LT-1500</h2>
                        <p className="text-gray-600 mb-2">Mix Note Value Counter cum Fake note Detection Machine with Customer Display</p>
                    </div>
                </div>

                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img4} alt="Mix Value Counter HI2222" className="w-full md:w-full h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Mix Value Counter HI2222</h2>
                        <p className="text-gray-600 mb-2">Mix Note Value Counter cum Fake note Detection Machine with Customer Display</p>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img5} alt="Lotus Black (Double Display)-LT-1000" className="w-full md:w-full h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Lotus Black (Double Display)-LT-1000</h2>
                        <p className="text-gray-600 mb-2">Mix Note Value Counter cum Fake note Detection Machine with Customer Display</p>
                    </div>
                </div>

                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img6} alt="Lotus Black (Double Display)-LT-6000 Pro (Touch Pad)" className="w-full md:w-full h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Lotus Black (Double Display)-LT-6000 Pro (Touch Pad)</h2>
                        <p className="text-gray-600 mb-2">Mix Note Value Counter cum Fake note Detection Machine with Customer Display</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MixValue;
