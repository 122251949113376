import React from 'react';
import home from '../../img/CCTV (2).png';

function Homepage7() {
  return (
<div className="sm:h-screen pt-10  bg-center bg-no-repeat bg-gradient-to-r from-gray-500 to-black ">
      <section className="dark:bg-gray-100 dark:text-gray-800 bg-opacity-75">
        <div className="container flex flex-col justify-center  p-6 mx-auto sm:py-12 lg:py-24 xl:py-32 lg:flex-row lg:justify-between">
          <div className="flex flex-col justify-center mt-28 p-6 text-center text-white rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
          <h1 className="text-5xl font-bold leading-none sm:text-6xl">CCTV (Closed Circuit Television) 
            </h1>
            <p className="mt-6  text-lg sm:mb-12">Enhance Security and Surveillance with Our CCTV Systems
              <br className="hidden md:inline lg:hidden" />Your trusted source for cutting-edge CCTV solutions. Explore our range of Closed Circuit Television systems designed to enhance security and surveillance. 


            </p>
            <div className="">
              <a rel="noopener noreferrer" href="#" className="inline-block px-6 py-3 text-lg font-semibold leading-none text-white bg-blue-500 rounded hover:bg-blue-600">Know More</a>
            </div>
          </div>
          <div className="flex items-center justify-center p-6 mt-2 lg:mt-0">
            <img src={home} alt="Cash Counting Machine" className="object-contain max-h-96 sm:max-h-96 lg:max-h-96 xl:max-h-96 2xl:max-h-112" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Homepage7;
