import React from 'react';
import mainimg from '../../../img/GOLD_TESTING_MACHINE-body.png';
import lada1 from '../../../img/Ladagold.png';
import lada2 from '../../../img/ladagold_parts.png';
import Fischer1 from '../../../img/gold_fischer1.png';
import Fischer2 from '../../../img/gold_fischer2.png';

import { Link } from 'react-router-dom';

function GoldMachine() {
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-100">
            <h1 className="text-5xl text-center font-bold mb-12 text-rose-800">Gold Testing Machine</h1>
            <div className="flex flex-col lg:flex-row items-center justify-between">
                <div className="lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
                <p className='text-3xl text-center font-bold mb-8 text-rose-800'>Lotus XRF-10 gold testing machine</p>

                    <img src={mainimg} alt="Gold testing machine" className="w-full " />
                    <Link to='/cashform'>
                        <button className="bg-blue-500 hover:bg-blue-700 w-full lg:w-96 text-white font-bold py-2 px-4 rounded mt-4 transition duration-300 ease-in-out transform hover:scale-105">Enquiry Now</button>
                    </Link>
                </div>
                <div className="lg:w-1/2 space-y-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specification</h3>
                        <table className="w-full text-left border-collapse">
                            <tbody>
                                {[
                                    { label: "ACCURACY", value: "0.01% (Au<999) / 0.1% - Better than Fire-Assay" },
                                    { label: "X-RAY SOURCE", value: "W-Target X-Ray tube with Triple Stage Cooling" },
                                    { label: "SYSTEM", value: "Built-In Industrial Grade Computer with Extended Display" },
                                    { label: "COLLIMATOR", value: "Most optimum size < 2mm (optional 1mm)" },
                                    { label: "ANALYSIS ELEMENTS", value: "From Al (13) to U (92) around 79 elements Activated Max 25+ Elements" },
                                    { label: "ANALYSIS RANGE", value: "1% ~ 99.99%" },
                                    { label: "TESTING TIME", value: "15 – 60 seconds (User Selectable) (Latest Software with Live Results)" },
                                    { label: "HIGH VOLTAGE", value: "0-50 kV / 0-1 mA" },
                                    { label: "POWER SUPPLY", value: "110V - 260V, 50/60 Hz" },
                                ].map((spec, index) => (
                                    <tr key={index} className="border-b">
                                        <th className="font-semibold p-2">{spec.label}</th>
                                        <td className="p-2">{spec.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li>High Accuracy Results with HDSP technology (Hybrid Digital Signal Processor)</li>
                            <li>Detects 30+ Elements Precisely better than Fire Assay</li>
                            <li>Identifies Prohibited Elements (Powder) Iridium, Ruthenium, Osmium, etc.</li>
                            <li>Industrial Integrated PC With External Display</li>
                            <li>Automatic Calibration</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="space-y-20 mt-12">
                <div className='flex flex-col md:flex-row md:items-center justify-around'>
                    <div className='p-8 md:w-1/2  bg-white rounded-lg shadow-lg overflow-hidden'>
                        <h2 className='text-2xl font-bold mb-2 text-rose-800'>LADA XRF-10 Gold Purity Testing Machine</h2>
                        <p className='text-gray-700 mb-2'>Accuracy: RSD (+/- 0.3%)</p>
                        <p className='text-gray-700 mb-2'>Measuring Elements: Au, Ag, Pt, Ni, Cu, Zn</p>
                        <p className='text-gray-700 mb-2'>Camera: HD Camera</p>
                        <p className='text-gray-700'>Analysis Range: 1 ppm to 99.99%</p>
                    </div>
                    <div className=' grid grid-cols-1 '>
                        <img src={lada1} alt="LADA XRF-10" className='w-full h-72 object-cover' />
                        <img src={lada2} alt="LADA XRF-10 Parts" className='w-full h-48 object-cover' />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row md:items-center'>
                    <div className='p-8 md:w-1/2  bg-white rounded-lg shadow-lg overflow-hidden'>
                        <h2 className='text-2xl font-bold mb-2 text-rose-800'>Fischer XRF-10 Gold Testing Machine</h2>
                        <p className='text-gray-700 mb-2'>Being a customer-oriented organization, we are deeply engaged in offering a wide array of Fischer Gold Testing Machines.</p>
                        <p className='text-gray-700 mb-2'>Optimized for Fast, Cost-effective, and Non-destructive Analysis of Jewelry, Coins, and Precious Metals.</p>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <div className=''>
                            <img src={Fischer1} alt="Fischer XRF-10" className='w-full h-auto object-cover' />
                        </div>
                        <div className=''>
                            <img src={Fischer2} alt="Fischer XRF-10" className='w-full h-auto object-cover' />
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    );
}

export default GoldMachine;
