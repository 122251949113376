import React from 'react';
import mainimg from '../../../img/cctv-body.png';
import img1 from '../../../img/exp-1.png';
import img2 from '../../../img/exp-2.png';
import img3 from '../../../img/exp-3.png';
import img4 from '../../../img/exp-4.png';
import img5 from '../../../img/exp-5.png';
import img6 from '../../../img/exp-6.png';
import dome from '../../../img/exp-6.png';
import bullet1 from '../../../img/bullet1.png';
import bullet2 from '../../../img/bullet2.png';
import bullet3 from '../../../img/bullet3.png';

import { Link } from 'react-router-dom';

function CCTV() {
    return (
        <div className="container mx-auto px-4 py-8 bg-gray-100">
            <h1 className="text-5xl text-center font-bold mb-8 text-rose-800">CCTV (Closed Circuit Television)</h1>
            <div className="flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-8">
                <div className="lg:w-1/2 lg:pr-4 mb-8 lg:mb-0 flex flex-col items-center">
                    <img src={mainimg} alt="CCTV" className="w-full  transition-transform duration-200 hover:scale-105" />
                    <Link to='/cashform' className="mt-4 w-full lg:w-auto">
                        <button className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded mt-2 transition-colors duration-200">Enquiry Now</button>
                    </Link>
                </div>

                <div className="lg:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md mb-4">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">Specification</h3>
                        <table className="w-full text-left">
                            <tbody>
                                <tr>
                                    <th className="font-semibold py-2">Image sensor</th>
                                    <td>1/3” or better type CMOS color Sensor</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Effective Pixels</th>
                                    <td>1920 X 1080</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Scanning System</th>
                                    <td>Progressive Scan</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Resolution</th>
                                    <td>1080p Full HD images up to 25/30 fps</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Frame Rate</th>
                                    <td>25 FPS/30 FPS for 1920 X 1080p at H.264 Compression or better</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Compression</th>
                                    <td>H.264 Compression (main profile/high profile) and MJPEG</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Bit Rate</th>
                                    <td>32kbps – 10Mbps</td>
                                </tr>
                                <tr>
                                    <th className="font-semibold py-2">Intelligent Video</th>
                                    <td>Motion detection etc.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h3 className="text-2xl font-bold mb-4 text-rose-800">CCTV Benefits</h3>
                        <ul className="list-disc pl-4 text-left space-y-2">
                            <li>Crime Prevention</li>
                            <li>Reduce Security Costs</li>
                            <li>Deterring Vandalism</li>
                            <li>Provides Camera Footage for Evidence</li>
                            <li>Handling Disputes</li>
                            <li>Monitoring Staff</li>
                            <li>Visibility of Entire Business Premises</li>
                            <li>Enhance Customer Services</li>
                            <li>Reduce Insurance Claims and Premiums</li>
                            <li>Peace of Mind</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap justify-evenly p-4 bg-rose-800 mt-20 rounded-lg shadow-lg">
    {[
        { img: img1, text: 'Network Camera' },
        { img: img2, text: 'NVR' },
        { img: img3, text: 'Analog Camera' },
        { img: img4, text: 'DVR' },
        { img: img5, text: 'Accessories' },
        { img: img6, text: 'Dome Camera' }
    ].map((item, index) => (
        <div key={index} className="text-center m-2">
            <img src={item.img} alt={item.text} className="w-20 h-20 mx-auto mb-2 transition-transform duration-200 hover:scale-105" />
            <p className="text-white font-semibold">{item.text}</p>
        </div>
    ))}
</div>


            <div className="container mx-auto px-4">
                <div className="mt-8 lg:flex lg:space-x-8">
                    <div className="lg:w-1/2">
                        <img src={dome} className="w-full h-auto mb-4 transition-transform duration-200 hover:scale-105" alt="Dome Camera" />
                        <h1 className='text-2xl font-bold mb-4 text-rose-800  text-center'>Dome Camera</h1>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                            <table className="w-full text-left">
                                <tbody>
                                    <tr>
                                        <th className="font-semibold py-2">Brand Name</th>
                                        <td>HikVision</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Type of Product</th>
                                        <td>Dome Camera</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Power Consumption</th>
                                        <td>Max. 3.7 W</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Power Supply</th>
                                        <td>12 VDC ± 25%</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Image Sensor</th>
                                        <td>5 MP CMOS</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Resolution</th>
                                        <td>2560x1944 P</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-8 lg:mt-0 lg:w-1/2">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                            {[bullet1, bullet2, bullet3].map((bullet, index) => (
                                <img key={index} src={bullet} className="w-full h-auto transition-transform duration-200 hover:scale-105" alt={`Bullet Camera ${index + 1}`} />
                            ))}

                        </div>
                        <h1 className='text-2xl font-bold mb-4 text-rose-800  text-center'>Bullet  Camera</h1>

                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-2xl font-bold mb-4 text-rose-800">Features</h3>
                            <table className="w-full text-left">
                                <tbody>
                                    <tr>
                                        <th className="font-semibold py-2">Brand</th>
                                        <td>HIKVISION</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Connectivity Technology</th>
                                        <td>Wireless</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Special Feature</th>
                                        <td>HD Resolution</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Indoor/Outdoor Usage</th>
                                        <td>Outdoor, Indoor</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Compatible Devices</th>
                                        <td>Laptop</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Power Source</th>
                                        <td>Corded Electric</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Connectivity Protocol</th>
                                        <td>Ethernet</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Controller Type</th>
                                        <td>Android</td>
                                    </tr>
                                    <tr>
                                        <th className="font-semibold py-2">Mounting Type</th>
                                        <td>Protruding</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CCTV;
