import React from 'react';
import img1 from '../../../../img/cash4 (2).png';
import img2 from '../../../../img/cash4.png';

function CISCurrency() {
    return (
        <div className=" mx-auto py-8 px-4">
            <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-rose-800">CIS Currency Value</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img1} alt="LT-150 (Bill Counter)" className="w-full md:w-96 h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Dual CIS Currency Value Counter With SN Reading Front Loading LT-930 A</h2>
                        <p className="text-gray-600 mb-2">Counterfeit Detection-Image, Magnetic, Infrared, Ultraviolet</p>
                        <p className="text-gray-600 mb-2">Serial Numbers Reading</p>
                        <p className="text-gray-600">Button Keypad and Big size TFT Display</p>
                    </div>
                </div>
                <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col md:flex-row items-center">
                    <img src={img2} alt="LT-001 (Semi Value)" className="w-full md:w-96 h-64 object-cover object-center" />
                    <div className="p-4 text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">Dual CIS Currency Value Counter With SN Reading Back Loading LT-820</h2>
                        <p className="text-gray-600 mb-2">Counterfeit Detection-Image, Magnetic, Infrared, Ultraviolet</p>
                        <p className="text-gray-600 mb-2">Batching, adding and self-examination functions</p>
                        <p className="text-gray-600 mb-2">Serial Numbers Reading</p>
                        <p className="text-gray-600">Touch Panel and 4.5 inch Touch Screen</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CISCurrency;
